import './styles.css';

interface Props {
  type: 'flat' | 'radial';
  show: boolean;
}

function Loader(props: Props) {
  const { type, show } = props;

  const _class = `loader ${type} ${show ? "" : "hidden"}`

  return (
    <span className={_class}></span>
  )
}

export { Loader };