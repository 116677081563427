import useConnection from './hooks/useConnection';
import { ErrorScreen } from './screens/ErrorScreen';
import { SearchScreen } from './screens/SearchScreen';
import './App.css';
import { LoadingScreen } from './screens/LoadingScreen';

function App() { 
  const connection = useConnection();

  const connected = connection.connected;
  let error: string = connection.error?.message;

  if (connection.error?.type === 'TransportError')
    error = 'Chyba spojenia';

  if (connection.error?.message === 'Unauthenticated')
    error = 'Prístup zamietnutý';

  return (
    <div className="app">
      { (!connected && !error) && <LoadingScreen /> }
      { (connected && !error) && <SearchScreen /> }
      { error && <ErrorScreen message={error} /> }
    </div>
  );
}

export default App;
