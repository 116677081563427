import {
  type ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { calculateBarData, draw } from "./utils";
import useAnimationFrame from "../../hooks/useAnimationFrame";

export interface Props {
  mediaStream?: MediaStream | null;
  barWidth?: number;
  gap?: number;
  backgroundColor?: string;
  barColor?: string;
  fftSize?:
    | 32
    | 64
    | 128
    | 256
    | 512
    | 1024
    | 2048
    | 4096
    | 8192
    | 16384
    | 32768;
  maxDecibels?: number;
  minDecibels?: number;
  smoothingTimeConstant?: number;
}

const LiveAudioVisualizer: (props: Props) => ReactElement = ({
  mediaStream,
  barWidth = 2,
  gap = 1,
  backgroundColor = "transparent",
  barColor = "rgb(160, 198, 255)",
  fftSize = 1024,
  maxDecibels = -10,
  minDecibels = -90,
  smoothingTimeConstant = 0.4,
}: Props) => {
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [context, setContext] = useState<AudioContext>();
  const [audioSource, setAudioSource] = useState<MediaStreamAudioSourceNode>();
  const [analyser, setAnalyser] = useState<AnalyserNode>();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const frequencyData = useRef<Uint8Array>();

  useEffect(() => {
    return () => {
      if (context && context.state !== "closed") {
        context.close();
      }
      audioSource?.disconnect();
      analyser?.disconnect();
      frequencyData.current = undefined;
    };
  }, [analyser, audioSource, context]);

  useEffect(() => {
    if (!mediaStream)
      return;

    const ctx = new AudioContext();
    const analyserNode = ctx.createAnalyser();
    analyserNode.fftSize = fftSize;
    analyserNode.minDecibels = minDecibels;
    analyserNode.maxDecibels = maxDecibels;
    analyserNode.smoothingTimeConstant = smoothingTimeConstant;
    const source = ctx.createMediaStreamSource(mediaStream);
    source.connect(analyserNode);

    setAnalyser(analyserNode);
    setContext(ctx);
    setAudioSource(source);

    return () => {
      setAnalyser(undefined);
      setContext(undefined);
      setAudioSource(undefined);
    };
  }, [
    mediaStream,
    fftSize,
    minDecibels,
    maxDecibels,
    smoothingTimeConstant,
  ]);

  useEffect(() => {
    const handleResize = () => {
      if (!canvasRef.current) return;
      setCanvasWidth(canvasRef.current.offsetWidth);
      setCanvasHeight(canvasRef.current.offsetHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [canvasRef, setCanvasWidth, setCanvasHeight]);
  
  const render = useCallback(() => {
    if (!canvasRef.current) return;

    const data = frequencyData.current;
    const width = canvasRef.current.offsetWidth;

    const dataPoints = calculateBarData(
      data,
      width,
      barWidth,
      gap
    );

    draw(
      dataPoints,
      canvasRef.current,
      barWidth,
      gap,
      backgroundColor,
      barColor
    );
  }, [
    backgroundColor,
    barColor,
    barWidth,
    gap,
  ]);

  const report = useCallback((timestamp: DOMHighResTimeStamp) => { 
    if (!analyser || !context) return;

    const data = new Uint8Array(analyser?.frequencyBinCount);
    frequencyData.current = data;

    analyser?.getByteFrequencyData(data);
    render();
  }, [render, analyser, context]);

  useAnimationFrame(report, !!analyser);

  useEffect(() => {
    render();
  });

  return (
    <canvas
      ref={canvasRef}
      width={canvasWidth}
      height={canvasHeight}
      style={{
        aspectRatio: "unset",
      }}
    />
  );
};

export { LiveAudioVisualizer };
