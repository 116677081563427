import './styles.css';

interface Props {
  message: string;
}

function ErrorScreen(props: Props) {
  return (
    <div className="error">
      <div className="message">{props.message}</div>
    </div>
  )
}

export { ErrorScreen };