import { useRef, useEffect, useCallback } from 'react';

type Callback = (timestamp: DOMHighResTimeStamp) => void;

function useAnimationFrame(exec: Callback, active: boolean) {
   const savedExec = useRef<Callback>();
   const interval = useRef<number | null>(null);

   useEffect(() => {
      savedExec.current = exec;
   }, [exec]);

   const step = useCallback((timestamp: DOMHighResTimeStamp) => {
      savedExec.current?.(timestamp);

      if (interval.current)
        requestAnimationFrame(step);
   }, []);

   const cancel = useCallback(() => {
      cancelAnimationFrame(interval.current!);
      interval.current = null;
   }, []);

   useEffect(() => {
      if (active) {
         interval.current = requestAnimationFrame(step);
      }
      return () => cancel();
   }, [active, step, cancel]);
}

export default useAnimationFrame;