import { Product as ProductType } from '../../types';
import './styles.css';

interface Props extends ProductType {
  onClick?: () => void;
}

function Product(props: Props) {
  return (
    <div className="product" onClick={() => props.onClick?.()}>
      <div className="image">
        <img src={props.imageUrl} alt={props.name} />
      </div>
      <div className="content">
        <div className="name">{props.name}</div>
        <div className="type">{props.type}</div>
        {/* <div className="category">{props.category}</div> */}
        <div className="price">{props.price.toFixed(2)} €</div>
      </div>
    </div>
  );
}

export { Product }