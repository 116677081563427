import { Loader } from '../../components/Loader';
import './styles.css';

interface Props {
}

function LoadingScreen(props: Props) {
  return (
    <div className="loading">
      <div className="container">
        <Loader type="radial" show={true} />
      </div>
    </div>
  )
}

export { LoadingScreen };