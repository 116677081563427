import { useState, useCallback, useEffect, useRef } from "react";

export type RecorderState = 'recording' | 'paused' | 'stopped'

export interface AudioRecorderControls {
  state: RecorderState;
  mediaStream: MediaStream | null;
  mediaRecorder: MediaRecorder | null;
  start: (timeslice?: number) => void;
  stop: () => void;
  pause: () => void;
  resume: () => void;
}

export type MediaAudioTrackConstraints = Pick<
  MediaTrackConstraints,
  | "deviceId"
  | "groupId"
  | "autoGainControl"
  | "channelCount"
  | "echoCancellation"
  | "noiseSuppression"
  | "sampleRate"
  | "sampleSize"
>;

export interface AudioRecorderOptions {
  audioTrackConstraints?: MediaAudioTrackConstraints;
  mediaRecorderOptions?: MediaRecorderOptions;
  onStart?: (recorder: MediaRecorder) => void;
  onStop?: (recorder: MediaRecorder) => void;
  onPause?: (recorder: MediaRecorder) => void;
  onResume?: (recorder: MediaRecorder) => void;
  onData: (recorder: MediaRecorder, data: Blob) => void;
}

const useAudioRecorder: (options: AudioRecorderOptions) => AudioRecorderControls = (
  options,
) => {
  const [state, setState] = useState<RecorderState>('stopped');
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);

  const mediaStreamRef = useRef<MediaStream | null | undefined>();
  const mediaRecorderRef = useRef<MediaRecorder | null | undefined>();
  
  const start: (timeslice?: number) => void = useCallback(async (timeslice?: number) => {
    if (mediaStream)
      return;

    const stream = await navigator.mediaDevices.getUserMedia({ 
      audio: options.audioTrackConstraints ?? true,
    });
     
    const recorder = new MediaRecorder(stream, options.mediaRecorderOptions);

    setMediaStream(stream);
    setMediaRecorder(recorder);
    recorder.start(timeslice);
  }, [
    options.audioTrackConstraints,
    options.mediaRecorderOptions,
    mediaStream,
    setMediaStream,
    setMediaRecorder,
  ]);

  const stop: () => void = useCallback(() => {
    mediaRecorderRef.current?.stop();
  }, [mediaRecorderRef]);

  const pause: () => void = useCallback(() => {
    mediaRecorderRef.current?.pause();
  }, [mediaRecorderRef]);

  const resume: () => void = useCallback(() => {
    mediaRecorderRef.current?.resume();
  }, [mediaRecorderRef]);

  const onStart: () => void = useCallback(() => {
    setState('recording');
    options.onStart?.(mediaRecorder!);
  }, [
    setState, 
    options, 
    mediaRecorder
  ]);

  const onStop: () => void = useCallback(() => {
    mediaStream?.getTracks()?.forEach((track) => track.stop());
    setState('stopped');
    setMediaStream(null);
    setMediaRecorder(null);
    options.onStop?.(mediaRecorder!);
  }, [
    setState, 
    setMediaStream,
    setMediaRecorder,
    options, 
    mediaStream,
    mediaRecorder,
  ]);

  const onPause: () => void = useCallback(() => {
    setState('paused')
    options.onPause?.(mediaRecorder!);
  }, [
    setState, 
    options, 
    mediaRecorder,
  ]);

  const onResume: () => void = useCallback(() => {
    setState('recording');
    options.onResume?.(mediaRecorder!);
  }, [
    setState, 
    options, 
    mediaRecorder,
  ]);

  const onData: (event: Event) => void = useCallback((event: Event) => {
    options.onData?.(mediaRecorder!, (event as BlobEvent).data);
  }, [
    options, 
    mediaRecorder,
  ]);

  useEffect(() => {
    return () => {
      const recorder = mediaRecorderRef.current;
      const stream = mediaStreamRef.current;
      recorder?.stop();
      stream?.getTracks()?.forEach((track) => track.stop());
    }
  }, [
    mediaStreamRef, 
    mediaRecorderRef,
  ]);

  useEffect(() => {
    mediaStreamRef.current = mediaStream;
    mediaRecorderRef.current = mediaRecorder;

    if (!mediaRecorder)
      return;

    mediaRecorder?.addEventListener('start', onStart);
    mediaRecorder?.addEventListener('stop', onStop);
    mediaRecorder?.addEventListener('pause', onPause);
    mediaRecorder?.addEventListener('resume', onResume);
    mediaRecorder?.addEventListener('dataavailable', onData);

    return () => {
      mediaRecorder?.removeEventListener('start', onStart);
      mediaRecorder?.removeEventListener('stop', onStop);
      mediaRecorder?.removeEventListener('pause', onPause);
      mediaRecorder?.removeEventListener('resume', onResume);
      mediaRecorder?.removeEventListener('dataavailable', onData);
    }
  }, [
    onStart, 
    onStop, 
    onPause, 
    onResume, 
    onData,
    mediaStream,
    mediaRecorder,
  ]);

  return {
    state,
    mediaStream,
    mediaRecorder,
    start,
    stop,
    pause,
    resume,
  };
};

export default useAudioRecorder;