import { useSocket } from 'socket.io-react-hook';

const useConnection = () => {
  const url = (window as any).env.WS_URL || undefined;
  const params = new URLSearchParams(window.location.search);

  return useSocket(url, {
    auth: {
      token: params.get('token'),
    },
  });
};

export default useConnection